.infoUI {
    .ant-collapse {
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }
    
    .ant-list * {
        p {
            font-family: "Segoe UI";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
        }
    }
    .ant-collapse-header {
        font-weight: 600;
    };
    .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header, .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow{
        color: unset;
        cursor: unset;
    }
}
.ant-space.ant-space-horizontal.ant-space-align-center.btnsDrawer{
    margin: 0 0 15px 0;
}
.drawerUnderline{
    margin-bottom: 25px;
    border-color:#f0f0f02b;
}
.btnsDrawer{
    font-weight: 600;
}
