.userComment{
    .btnCol{
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
        .ant-btn{
            background-color: #7CC58F;
            border-color: #7CC58F;
            color: white;
        }
    }
}