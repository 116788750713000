.callInfo {
    .userInfo {
        margin: 20px 0;
        .action {
            cursor: pointer;
            color: blue;
            text-decoration: underline;
        }
    }
    .endCallBtn {
        float: right;
    }
    .requests {
        .ant-divider.ant-divider-horizontal.ant-divider-with-text.ant-divider-with-text-left {
            margin: 0 0 15px 0;
        }
       
    }
    span.ant-divider-inner-text {
        margin: 0 -65px; 
        
        //  ::before {
        //     top: 50%;
        //     width: 0% !important;
        // }
    }
}
.freePeriodInfo{
    div.ant-collapse-content-box{
        padding: 0;
    }
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
    top: 50%;
    width: 5%;
}
.reqActions {
    margin: 0 0 15px 0 !important;
}
.action {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}
.collectionDate {
    .ant-col {
        .ant-form-item-control-input {
            .ant-form-item-control-input-content {
                .ant-picker {
                    width: 100%;
                }
            }
        }
    }
}

.collectionAmount {
    .ant-col {
        .ant-form-item-control-input {
            .ant-form-item-control-input-content {
                .ant-input-number {
                    width: 95%;
                }
            }
        }
    }
}
.collectionFormBtns{
    display: flex;
    justify-content: flex-end;
    &__btn{
        margin-right: 1rem;
    }
}