.createEarlyPayment {
    .inputsRow {
        margin-top: 15px ;
        .ant-col {
            padding: 0px 8px;
            .ant-input-number, .ant-picker{
                width: 100%;
            }
            .ant-form-item-control{
                padding: 0;
            }
            button {
                margin: 22px 0 0 0;
                width: 100px;
                float: right
            }
        }
    }
}