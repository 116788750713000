/*userInfo*/
 .deduct {  // if that's, where you want them to rule only
    .ant-row {
        justify-content: center;
        padding: 6px 0;
    }
    .btn {
        margin: 15px auto;
        display: flex;
        width: 50%;
        background-color: $deepTeal;
        border-color: $deepTeal;
        &:hover {
            background-color: $deepTeal;
            border-color: $deepTeal;
        }
        &:focus {
            background-color: $deepTeal;
            border-color: $deepTeal;
        }
        svg {
            color: #fff;
        }
        span {
            margin: 0 auto;
        }
    }

}