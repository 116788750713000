.driverInfo {
    justify-content: center;
    display: flex;
    margin: 40px 0;
    @media #{$small-mobile} {
        text-align: center;
    }
    input#nest-messages_phone, .ant-select {
        height: 40px;
        width: 300px;
        font-size: 15px;
        @media #{$small-mobile} {
            width: 200px;
        }
        @media #{$extra-small-mobile} {
            width: 140px;
        }
    }
    .ant-input, .ant-select {
        transition: 0.3s;
        &:hover {
            border-color: #000;
        }
        &:focus {
            outline: none !important;
            border-color: #000;
            box-shadow: none !important;
        } 
    }

    span.ant-select-selection-placeholder {
        font-weight: 400;
    }
    button.ant-btn.ant-btn-primary.ant-btn-round.ant-btn-block.ant-btn-rtl.driverInfoBtn {
        width: 150px;
        background-color: #6fb76c;
        color: #fff;
        border-color: #6fb76c;
        height: 35px;
        border-radius: 5px;
        margin: 15px auto;
        justify-content: center;
        display: flex;
        font-size: 15px;
        transition: 0.3s;
        @media #{$small-mobile} {
            width: 100px;
        }
        @media #{$extra-small-mobile} {
            width: 60px;
        }
        // &:hover {
        //     background-color: #fff;
        //     color: #000;
        // }
    }
}
.driverExpenses {
    border-bottom: 1px solid #ddd;
    margin: 0 0 15px 0;
}
