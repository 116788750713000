// Font Family
$robotobold: 'Roboto-Bold';
$robotomedium: 'Roboto-Medium';
$robotoregular: 'Roboto-Regular';

// common colors
$deepTeal : #004e64;
$lightGreen: #7CC58F;
// text Colors ---------------
$white: #ffffff;
$davygray: #555555;
$davylight: #00000099;
$blue: #0064B1;
$lightblue:#026EFF52;
$lightgray: #0000008A;
$pageBackGround: #F2F3F5;
$lightgrayv2: #A6A6A6;
$grayborder: #F1F1F1;
$textblack: #1D2129;
$lightbluenotifications :#E5EFF7;
$error:#ff000e;

// bg Colors ---------------
$bg-blue: #0064B1;
$bg-white: #ffffff;
$bg-grey: #000000;
$bg-red: #FF0202;
$disabled:#9f9f9fb8;
// Theme Color
$xxxl-layout : 'only screen and (min-width: 1921px) and (max-width: 2800px)';
$xxl-layout : 'only screen and (min-width: 1601px) and (max-width: 1920px)';
$xx-layout : 'only screen and (min-width: 1366px) and (max-width: 1600px)';
$xl-layout : 'only screen and (min-width: 1200px) ';
$lg-layout : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs-layout:'only screen and (max-width: 767px)';
$sm-layout: 'only screen and (min-width: 576px) and (max-width: 767px)';
$small-mobile: 'only screen and (max-width: 575px)';
// $mini-small-mobile: 'only screen and (max-width: 580px)';
$extra-small-mobile: 'only screen and (max-width: 479px)';

