.timer{
    text-align: left;
    padding: 0 17px;
    color: #000000bf;
    p{
        margin: 0 !important;
    }
    .counter{
        padding-bottom: 10px;
    }
    .asking{
        font-size: 13px;
    }
    .resending{
        font-weight: 700;
        text-decoration: underline;
        cursor: pointer;
        color: #000000bf;
    }
    .options{
        margin-bottom: 20px;
        &>span{
            margin-right: 10px;
            font-weight: 700;
            color: #000000bf;
        }
    }
}