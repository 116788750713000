.emailsForm{
    .topButtons{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        button{
            margin-right: 10px;
        }
    }
    form{
        padding: 0 12px;
        .enableForm{
            margin-right: 20px;
            margin-top: 3px;
        }
        span.ant-divider-inner-text {
            margin: 0 -65px; 
        }
        .ant-col{
            padding: 0 10px 5px 0
        }
        .logoCol{
            text-align: center;
            margin: 25px auto;
        }
        .contactUs, .installmentDescription {
            textarea.ant-input{
                height: 32px;
            }
        }
        .serviceFees{
            .ant-input-number{
                width: 100%;
            }
        }
    }

}