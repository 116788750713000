.transactionErrorModal{
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-result{
        padding: 25px 32px;
    }

    p{
        font-size: 18px;
    }
}