.companiesGroup{
    .ant-form {
        .ant-row {
            .ant-col {
                padding: 0 10px 5px 0;
                .ant-input-number {
                    width: 100%;
                }
                .ant-btn-primary {
                    float: right;
                    margin-top: 12px;
                }
                span.ant-upload-list-item-name {
                    color: rgb(29, 29, 29);
                }
                .ant-upload-list-item-error {
                    color: #d9d9d9 !important;
                    border-color: #d9d9d9 !important;
                }
               
            }
        }
    }
}