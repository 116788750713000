.recalculatedRequests{
    .ant-table{
        margin-bottom: 25px;
    }
    .btnCol{
        display: flex;
        justify-content: flex-end;
        button{
            width: 120px;
        }
    }
    .ant-divider{
        margin-left: -50px;
    }
}
.installmentsComparison{
    .ant-table{
        margin-bottom: 25px;
    }
    .ant-divider{
        margin-left: -50px;
    }
}