.deduction {
    .deductionsFilter {
        padding: 20px 0;
        .btnRows {
            margin: 10px 0px;
            display: flex;
            justify-content: center;
        }
        .ant-col {
            padding: 5px 0;
            .ant-form-item-control-input-content {
                max-width: 90%;
            }
            .ant-btn,
            .ant-btn:active,
            .ant-btn:focus {
                width: 135px;
            }
            .ant-picker-range {
                width: 100%;
            }
        }
    }
    .totalDeductions{
        font-weight: bold;
        font-size: 20px;
        div{
            display: inline;
        }
    }
}
.invoice {
    margin: 0 40px;
    .nowPayLogo {
        margin: 25px auto;
        text-align: center;
        h2 {
            color: #0000009e;
        }
    }
    .invoiceHeader {
        .leftSide {
            line-height: 0.8;
            h3,
            h5 {
                color: #7285a4;
            }
            .address {
                color: #000;
            }
            h5 {
                span {
                    color: #000;
                }
            }
        }
        .rightSide {
            float: right;
            line-height: 0.8;
            text-align: end;
            h3,
            h5 {
                color: #7285a4;
            }
            .claim {
                font-size: 30px;
                margin-left: -2px;
                font-family: revert;
            }
            h5 {
                span {
                    color: #000;
                }
            }
        }
    }
    .invoiceBody {
        table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
            th {
                text-align: center;
            }
            td,
            th {
                border: 1px solid #dddddd;
                padding: 8px;
            }
            tr {
                td {
                    span {
                        float: right;
                    }
                }
            }
        }

        .total {
            float: right !important;
            margin: 10px 9px 0 0;
            border-bottom: 1px solid;
            font-family: arial, sans-serif;
            .egp {
                margin: 0 75px 0 0;
                padding: 0 0 0 12px;
            }
        }
    }
    .invoiceFooter {
        margin: 60px 0 0 0;
        .contact,
        .signature {
            font-size: 13px;
        }
        .contact {
            span {
                text-decoration: underline;
                color: #7285a4;
            }
        }
        p.signature{
            margin-bottom: 0;
        }
        .signature {
            span {
                .signatureWord {
                    position: relative;
                    top: 16px;
                    font-size: 14px;
                }
                img {
                    width: 300px;
                }
            }
        }
        .stamp{
            display: block;
            width: 230px;
            margin-left: 100px;
            margin-top: -70px;
            transform: rotate(-15deg);
        }
    }
}
