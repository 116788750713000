.hrUsers {
    padding: 10px 20px;
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $lightGreen;
    }
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        background-color: $lightGreen;
    }
    .ant-tabs-tab {
        &:hover {
            color: $lightGreen;
        }
        &:focus {
            color: $lightGreen;
        }
    }
    .ant-table-wrapper-rtl
        .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
    }
    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
        width: 21%;
    }
    .li.ant-menu-item {
        float: left;
        text-align: left;
        direction: rtl;
    }
    ul.ant-pagination.ant-pagination-rtl.ant-table-pagination.ant-table-pagination-left {
        float: right;
    }
    .ant-table-tbody > tr > td {
        padding: 5px 18px;
    }
}
.consentTable {
    color: #1890ff;
    text-decoration: underline;
    cursor: pointer;
}
.approve,
.refuse,
.edit {
    width: 70px;
    height: 32px;
    // margin-left: 12px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 5px;
    margin: .8em 0 5px 12px;
}
.approve {
    background-color: $lightGreen;
    border: none;
    &:hover {
        background: $lightGreen;
        border: none;
    }
    &:focus {
        background: $lightGreen;
        border: none;
    }
}
.refuse {
    background-color: #cc4949;
    border: none;
    color: #fff;
    &:hover {
        background: #cc4949;
        border: none;
        color: #fff;
    }
    &:focus {
        background: #cc4949;
        border: none;
        color: #fff;
    }
}
.edit {
    background-color: #fff;
    &:hover {
        background: #fff;
        border: 1px solid #d9d9d9;
        color: #000;
    }
    &:focus {
        background: #fff;
        border: 1px solid #d9d9d9;
        color: #000;
    }
}
.view {
    .ant-btn > .ant-btn-loading-icon .anticon svg {
        color: #000 !important;
    }
    
    background-color: #fff;
    &:hover {
        background: #fff;
        border: 1px solid #d9d9d9;
        color: #000;
    }
    &:focus {
        background: #fff;
        border: 1px solid #d9d9d9;
        color: #000;
    }
}

li.ant-menu-item {
    // text-align: end !important;
    &:hover {
        color: #000 !important;
        background: #7cc58f36;
    }
}
.styles-module_wrapper__1I_qj {
    // opacity: 0.9;
}
.changeUserStatus {
    .ant-select.ant-select-in-form-item {
        width: 98%;
        margin: 5px 0 0 0;
    }
    .label {
        font-weight: 500;
    }
    .question {
        text-align: right;
        margin: 0 0 25px 0;
        line-height: 25px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
    }
    .approve {
        width: 200px;
        background: #6fb76c;
        color: #fff;
        border-radius: 5px;
        &:hover {
            background: #6fb76c;
            color: #fff;
        }
        &:active {
            color: #fff;
            background: #6fb76c;
            border-color: #6fb76c;
        }
        &:focus {
            color: #fff;
            background: #6fb76c;
            border-color: #6fb76c;
        }
    }
    .reject {
        width: 200px;
        background: #cc4949;
        color: #fff;
        border-radius: 5px;
        border-color: #cc4949;
        margin: 0 0px 0 10px;
        &:hover {
            background: #cc4949;
            color: #fff;
        }
        &:active {
            color: #fff;
            background: #cc4949;
            border-color: #cc4949;
        }
        &:focus {
            color: #fff;
            background: #cc4949;
            border-color: #cc4949;
        }
    }
    .decline {
        width: 200px;
        margin: 0 0 0 10px;
        background: #fff;
        color: #000;
        border-radius: 5px;
        border: 1px solid #d9d9d9 !important;

        &:hover {
            background: #fff;
            color: #000;
            border: 1px solid #d9d9d9 !important;
        }
        &:active {
            background: #fff;
            color: #000;
        }
        &:focus {
            background: #fff;
            color: #000;
        }
    }
}
