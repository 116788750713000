.refundRequest{
    margin-top: 25px;
    .ant-divider-inner-text{
        margin-left: -50px;
    }
    form{
        margin-top: 15px;
        .ant-input-number{
            width: 100%;
        }
        .ant-col{
           margin-bottom: 10px;
        }
        .btnCol{
            display: flex;
            justify-content: flex-end;
            button{
                max-width: 15%;
            }    
        }
    }
}