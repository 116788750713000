.phoneSection{
    .react-tel-input {
        .country-list {
            text-align: left;
            overflow-y: hidden !important;
        }
    }
    .validNumber {
        float: left;
        color: $error;
    }
    .phoneBtn {
        width: 300px !important;
        float: left;
        margin: 10px 0px;
        border-radius: 5px;
        background-color: #1890ff;
        border-color: #1890ff;
    }
    .disabled-ant-btn {
        width: 300px !important;
        float: left;
        margin: 10px 0px;
        border-radius: 5px;
        color: #fff;
        background-color: $disabled;
        border-color: $disabled;
        &:hover{
            background-color: $disabled;
            border-color: $disabled;  
        }
    }
}

