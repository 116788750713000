.createRequest,.viewFees {
    .caption{
        width: 500px;
        margin: 0 auto;
    }
    .ant-steps-item-icon {
        border-color: $deepTeal !important;
    }
    .anticon svg {
        color: $deepTeal;
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
        color: $deepTeal;
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: $deepTeal;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
        background: $deepTeal;
    }
    .ant-descriptions{
        margin-top: 20px;
        .ant-descriptions-title{
            color: $deepTeal ;
            font-weight: normal;
            margin-bottom: -10px;
        }
    }

    .phoneNumber {
        margin: 50px auto 0 auto;
        .phoneBtn {
            width: 300px;
            margin: 10px auto 0;
            background: $deepTeal !important;
            border-color: $deepTeal !important;
            color: #fff;
            svg {
                color: #fff;
            }
        }
        .disabled-ant-btn {
            width: 300px;
            margin: 10px auto 0;
            background: #ddd !important;
            border-color: #ddd !important;
            color: #fff;
        }
        .validNumber {
            color: $error;
        }
    }

    .salarySlider {
        margin: 50px auto 0 auto;
        .sliderText {
            margin: 0 auto 10px auto;
            justify-content: center;
            display: flex;
            font-size: 18px;
            color: $deepTeal;
        }
        .salaryAdvance {
            margin: 0 auto;
            display: flex;
        }

        .submitSlider {
            text-align: center;
            margin: 25px auto 0;
            display: flex;
            background: $deepTeal !important;
            border-color: $deepTeal !important;
        }
        .submitSliderDisabled {
            text-align: center;
            margin: 25px auto 0;
            display: flex;
        }
        .submitSlider {
            svg {
                color: #fff;
            }
        }
    }

    .paymentPlan {
        text-align: center;
        margin: 40px auto;
        .EGP {
            margin: 0;
            padding: 0;
        }
        .numberShouldPay {
            font-size: 25px;
            font-weight: 700;
            color: $deepTeal;
        }
        .unValidMessage {
            font-size: 17px;
            font-weight: 700;
            color: $error;
        }
        .plansContainer {
            margin: 20px 0;
            .payBack {
                font-size: 18px;
                color: $deepTeal;
                padding: 0;
                margin: 20px 0;
            }
            .plans {
                height: 25px;
                width: 25px;
                border: 1px solid $deepTeal;
                color: $deepTeal;
                background-color: #fff;
                border-radius: 50%;
                display: inline-block;
                margin: 5px 10px 0;
                cursor: pointer;
            }
            .selectedPlan {
                height: 25px;
                width: 25px;
                border: 1px solid $deepTeal;
                background-color: $deepTeal;
                color: #fff;
                border-radius: 50%;
                display: inline-block;
                margin: 5px 10px 0;
                cursor: pointer;
            }

            .unValidPlan {
                background: #fff;
                color: $error;
                border: 1px solid $error;
                height: 25px;
                width: 25px;
                border-radius: 50%;
                display: inline-block;
                margin: 5px 10px 0;
                font-size: 15px;
            }
            .disabledPlans {
                background: #ddd;
                color: #b3b3b3;
                border: 1px solid #ddd;
                height: 25px;
                width: 25px;
                border-radius: 50%;
                display: inline-block;
                margin: 5px 10px 0;
                font-size: 15px;
                cursor: pointer;
            }
            .selectedunValidPlan {
                height: 25px;
                width: 25px;
                border: 1px solid $error;
                background-color: $error;
                color: #fff;
                border-radius: 50%;
                display: inline-block;
                margin: 5px 10px 0;
                font-size: 15px;
            }
        }

        .submitPayment {
            margin: 25px 0 !important;
            background: $deepTeal !important;
            border-color: $deepTeal !important;
            svg {
                color: #fff;
            }
        }
        .submitPaymentDisabled {
            margin: 25px 0 !important;
            background: #ddd !important;
            border-color: #ddd !important;
            svg {
                color: #fff;
            }
            color: #fff;
        }
    }
    .otp{
        margin-top: 25px;
        text-align: center;
        .pincode-input-text{
            border: 1px solid #ddd !important;
        }
        .timeOut{
            color: $error;
        }
        .timer{
            margin-left: 15px;
        }
        .bin-disabled {
            color: #fff;
            background-color: $disabled !important;
            border-color: $disabled !important;
        }
        }
}
