.filter {
    margin: 30px 40px 0;
    @media #{$small-mobile} {
        width: 73%;
    }
    @media #{$extra-small-mobile} {
        width: 73%;
    }

    .box {
        overflow-y: scroll;

        .ant-segmented {
            background: transparent;
            box-shadow: none;
            &:hover {
                background: none;
                border: none;
                box-shadow: none;
            }
        }
        .ant-segmented-item-selected {
            background-color: #6fb76c;
            border-radius: 2px;
            color: #fff;
            border: 1px solid;
            margin: 0 0 0 5px;
            padding: 5px;
            transition: 0.2s;
            &:hover {
                color: none;
            }
        }
        .ant-segmented-item {
            border: 1px solid;
            margin: 0 0 0 10px;
            padding: 5px;
            border-radius: 5px;
            transition: 0.2s;
        }
    }

    /* CSS button */
    .dashboardBtn {
        float: left;
        background-color: #6fb76c;
        border: 0 solid #e5e7eb;
        box-sizing: border-box;
        color: #fff;
        display: flex;
        font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial,
            "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 1rem;
        font-weight: 700;
        justify-content: center;
        line-height: 1.75rem;
        padding: 0.75rem 1.65rem;
        position: relative;
        text-align: center;
        text-decoration: none #000000 solid;
        text-decoration-thickness: auto;
        max-width: 460px;
        position: relative;
        cursor: pointer;
        transform: rotate(-2deg);
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        &:focus {
            outline: 0;
        }
        &:after {
            content: "";
            position: absolute;
            border: 1px solid #000000;
            bottom: 4px;
            left: 4px;
            width: calc(100% - 1px);
            height: calc(100% - 1px);
        }
        &:hover:after {
            bottom: 2px;
            left: 2px;
        }
        @media #{$small-mobile} {
            float: none;
            font-size: 1rem;
            margin-bottom: 0.8em;
            padding: 0.5em 1.5em;
        }
        @media #{$extra-small-mobile} {
            float: none;
            font-size: 1rem;
            margin-bottom: 0.8em;
            padding: 0.5em 1.5em;
        }
    }

    /*End CSS button */
}
.expenses,
.driversList {
    padding: 20px 40px;
    th.ant-table-cell {
        color: #bfbfbf;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: #6fb76c;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #6fb76c;
        border-color: #6fb76c;
    }
    .ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
        justify-content: center;
    }
    .ant-pagination-disabled .ant-pagination-item-link,
    .ant-pagination-disabled:hover .ant-pagination-item-link,
    .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
        color: #6fb76c;
        border-color: #6fb76c;
        cursor: not-allowed;
        border: none;
    }
    .ant-pagination-item-active a {
        background: #6fb76c;
        color: #fff;
        border-radius: 50%;
    }
    .ant-pagination-item-active {
        border: none;
    }
    .ant-pagination-item-active:focus-visible a,
    .ant-pagination-item-active:hover a {
        color: #fff;
    }
    button.ant-pagination-item-link {
        border: none;
    }
    li.ant-pagination-item.ant-pagination-item {
        border: none;
    }
    .ant-pagination-item:focus-visible a,
    .ant-pagination-item:hover a {
        color: none;
    }
    .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
            [colspan]
        )::before {
        display: none;
    }
}

.ant-modal-content {
    width: fit-content !important;
    margin: auto;
}
.declineRequest,
.resolveIssue {
    @media #{$small-mobile} {
        text-align: center;
    }
    @media #{$extra-small-mobile} {
        text-align: center;
    }
    .question {
        text-align: center;
        margin: 0 0 25px 0;
    }
    .approve {
        width: 200px;

        background: #6fb76c;
        color: #fff;
        border-radius: 5px;
        &:hover {
            background: #6fb76c;
            color: #fff;
            border: 1px solid !important;
        }
        &:active {
            color: #fff;
            background: #6fb76c;
            border-color: #6fb76c;
        }
    }
    .decline {
        width: 200px;
        margin: 0 0 0 10px;
        background: #fff;
        color: #6fb76c;
        border-radius: 5px;
        &:hover {
            background: #fff;
            color: #6fb76c;
            border: 1px solid #6fb76c !important;
        }
        &:active {
            color: #fff;
            background: #6fb76c;
            border-color: #6fb76c;
        }
    }
}
