.createAdjustment, .createLostCase, .createLostCollection, .deleteLost {
    margin-top: 30px;
        .ant-row {
            margin-bottom: 25px;
            .ant-col {
                padding: 0px 10px;
                .ant-form-item-control{
                    padding: 0;
                    .ant-input-number, .ant-picker{
                        width: 100%;
                    }
                }
                .infoLabel{
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .btnCol{
                display: flex;
                justify-content: flex-end;
                button{
                    width: 120px;
                }
            }
        }
        .amountsInfo{
            display: flex;
            justify-content: center;
        }
}
.createLostCase, .createLostCollection{
    .btnCol{
        align-items: flex-end;
    }
}

.createLostCollection, .deleteLost{
    .ant-col.collectionDetails{
        margin-top: 25px;
    }
}