.ant-steps-item-icon {
    border-color: $deepTeal !important;
}
.cancelRequest {
    .anticon svg {
        color: $deepTeal;
    }
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: $deepTeal !important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: $deepTeal !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: $deepTeal !important;
}

.getRequestSection {
    margin: 40px 0;
    .ant-input-number {
        width: 100%;
    }
    .getRequestBtn {
        margin: 15px auto;
        display: flex;
        width: 50%;
        background-color: $deepTeal;
        border-color: $deepTeal;
        &:hover {
            background-color: $deepTeal;
            border-color: $deepTeal;
        }
        &:focus {
            background-color: $deepTeal;
            border-color: $deepTeal;
        }
        svg {
            color: #fff;
        }
        span {
            margin: 0 auto;
        }
    }
}
.cancelRequestSection {
    .cancelBtn {
        &:hover {
            background: #fff;
        }
    }
}
.confirmationText {
    font-size: medium !important;
}

#areaa_user_introduction-error {
    border-color: #ff4d4f;
}
.ant-row.ant-form-item {
    margin: 0;
}
