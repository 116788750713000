.approveDocs {
    .hrLetter {
        margin: 20px 0 !important;
    }
    .docs {
        .label {
            margin: 0 25px;
            font-family: auto;
            color: #595959;
        }
        .image {
            width: 50%;
            padding: 0 17px;
        }
        .approving,
        .submitting {
            margin: 25px 0;
        }
        .approving{
            button{
                margin: 0 5px;
                width: 130px;
            }
            .ant-btn > .ant-btn-loading-icon .anticon svg{
                color: #096dd9 !important;
            }
        }
        margin: 20px 0;
        box-shadow: -10px -10px 15px rgba(255,255,255,0.5),
            10px 10px 15px rgba(70,70,70,0.12);
    }
    .nationalId {
        margin: 20px 0 !important;
    }
}
