.bulkUpdateUserInfo{
    .fieldCol{
        padding: 10px;
        margin-bottom: 10px;
        .filedItem{
            padding: 10px;
            border: 1px solid rgb(227, 227, 227);
            border-radius: 5px;
            .ant-checkbox-wrapper{
                span{
                    color: rgb(72, 72, 72);
                    font-weight: 600;
                    font-size: 17px;
                }
            }
        }
    }
}