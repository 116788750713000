.getUserOtp {
    padding-top: 30px;

    .phoneNumberForm {
        text-align: center;
        margin-bottom: 50px;
        .ant-form-item-control {
            margin-bottom: 10px;
            .ant-input-number, button, .ant-select {
                width: 40%;
            }
        }
    }
}