.titleFont {
    font-weight: bold;
    font-size: 17px !important;
}

.formTitle {
    @extend .titleFont;
    margin-bottom: 20px;
    margin-left: -7px;
    .ant-divider-inner-text {
        @extend .titleFont;
        margin-left: -65px;
    }
}
.globalCapsForm{
    margin-top: -30px !important;
}
.servicesCapsForm,
.servicesSuspensionForm {
    label {
        display: block;
    }
    
    .servicesInputs {
        margin-bottom: 40px;
        display: flex;

        p {
            display: inline-block;
            font-weight: bold;
            margin-right: 8px;
        }

        .switchCol {
            margin-top: 10px;
        }

        .ant-form-item {
            display: inline-block;
            width: 100%;
            .ant-switch {
                margin-top: -13px;
            }

            .ant-input-number {
                width: 100%;
            }
        }
    }
}