.userInfo {
    .ant-input-number {
        width: 368px;
    }

    .ant-row {
        justify-content: center;
    }

    .phoneForm {
        .userBtn {
            margin: 15px auto;
            display: flex;
            width: 50%;
            background-color: $deepTeal;
            border-color: $deepTeal;

            &:hover {
                background-color: $deepTeal;
                border-color: $deepTeal;
            }

            &:focus {
                background-color: $deepTeal;
                border-color: $deepTeal;
            }

            svg {
                color: #fff;
            }

            span {
                margin: 0 auto;
            }
        }

        .errorMessage {
            color: $error;
        }
    }

    .infoInputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .inputFrame {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            // border: 1px solid #D9D9D9;
            padding: 8px 12px;
            // border-radius: 2px;
            width: 368px;
            height: 40px;

            .ant-input-number {
                border: none;
                text-align: center;
            }

            .icons {
                width: 16px;
                height: 16px;
            }
        }

        .orSpan {
            margin: 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
        }
    }
}