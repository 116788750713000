
    .ant-btn > .ant-btn-loading-icon .anticon svg{
        color: #fff !important;
    }
    .phoneNumber {
        margin: 50px auto 0 auto;
        .phoneBtn {
            width: 300px;
            margin: 10px auto 0;
            background: $deepTeal !important;
            border-color: $deepTeal !important;
            color: #fff;
            svg{
                color: #fff;
            }
        }
        .disabled-ant-btn {
            width: 300px;
            margin: 10px auto 0;
            background: #ddd !important;
            border-color: #ddd !important;
            color: #fff;
            span{
                color: #fff !important;
            }
        }
        .validNumber {
            color: $error;
        }
    }
    
    