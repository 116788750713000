.createNewCompany {
    .oldPhoto {
        border: 1px solid #ddd;
        padding: 7px 15px;
        width: 327px;
        height: 65px;
        margin: 10px 0;

        .placeholder {
            text-align: center;
            margin: 0 23px;
            justify-content: center;
        }

        .deleteIcon {
            float: right;
            margin: 10px -3px;
            color: red;
        }
    }
    .spocs{
        .ant-input-number{
            width: 100% !important;
        }
    }
    .ant-form {
        .ant-row {
            .ant-col {
                padding: 0 10px 5px 0;
                .ant-input-number {
                    width: 100%;
                }
                .ant-btn-primary {
                    float: right;
                    margin-top: 12px;
                }
                label {
                    span {
                        display: inline-block;
                        color: #ff4d4f;
                        font-size: 14px;
                        font-family: SimSun;
                        line-height: 1;
                        content: "*";
                    }
                }
                span.ant-upload-list-item-name {
                    color: rgb(29, 29, 29);
                }
                .ant-upload-list-item-error {
                    color: #d9d9d9 !important;
                    border-color: #d9d9d9 !important;
                }
                .ant-picker {
                    width: 100% ;
                }
            }
        }
        span.ant-divider-inner-text {
            margin: 0 -65px; 
        }
    }
}
