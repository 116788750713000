.addUserForm {
    .ant-form {
        .addUserBtn {
            text-align: end !important;
        }
        .ant-input-number {
            width: 100%;
        }
    }  
}
.ant-spin-spinning {
    display: block !important;  
 }
 