.user{
    .ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture{
        width: 70%;
    }
    margin: 20px 0;
    input{
        width: 90%;
        margin: 5px 0;
    }
    .ant-upload-list-picture .ant-upload-list-item-error, .ant-upload-list-picture-card .ant-upload-list-item-error{
        border-color: #ddd;
    }
    .ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-text-icon > .anticon, .ant-upload-list-item-error .ant-upload-list-item-name{
        color: #000;
    }
    .user .ant-upload-list-item-error, .user .ant-upload-list-item-error .ant-upload-text-icon > .anticon, .user .ant-upload-list-item-error .ant-upload-list-item-name{
        color: #000;
    }
}