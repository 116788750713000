.ant-row.ant-form-item{margin: 0 0 15px 0;}
.error{
    border: 1px solid #ff4d4f;
    &:focus{
        color: 0;
    }
}
.messageError{
    position: relative;
    left: 48px;
    font-size: 14px;
    color: #ff4d4f;
}
.ant-upload.ant-upload-select.ant-upload-select-text{
    float: right;
    padding-bottom: 20px;
}
.ant-modal{
    width: 800px !important;
}
.editUser input{
    width: 150px !important;
}