.ant-form-class {
    .ant-form {
        .ant-row {
            .ant-col {
                padding: 0 10px 5px 0;
                .ant-input-number {
                    width: 100%;
                }
                .ant-btn-primary {
                    float: right;
                    margin-top: 12px;
                }
                label {
                    
                    span {
                        display: inline-block;
                        color: #ff4d4f;
                        font-size: 14px;
                        font-family: SimSun;
                        line-height: 1;
                        content: "*";
                    }
                }
                span.ant-upload-list-item-name {
                    color: rgb(29, 29, 29);
                }
                .ant-upload-list-item-error {
                    color: #d9d9d9 !important;
                    border-color: #d9d9d9 !important;
                }
                .ant-picker {
                    width: 100%;
                }
            }
        }
    }
}
::-webkit-scrollbar {
    width: 4px;
    height: 13px;
}
::-webkit-scrollbar-thumb:horizontal{
    width: 5px;
    height: 3px;
    background: rgb(190, 190, 190);
    border-radius: 10px;
}

.ant-modal {
    .ant-modal-body {
        max-height: 600px;
        width: fit-content;
        overflow-y: auto;
    }
}

.sheetTemplate {
    span {
        span {
            color: #ff4d4f;
        }
    }
}
.schedule,
.demand,
.createDepartment,
.createTransfers,
.sendEmails {
    .ant-col-12 {
        padding: 5px 15px 10px 0;
    }
    .ant-col-18 {
        padding: 5px 15px 10px 0;
    }
    .ant-col-23 {
        padding: 5px 15px 10px 0;
    }
    .divider {
        margin: 10px 0 10px -40px;
        color: #717171;
        font-size: 20px;
    }
}

.applyNewDesign {

    .ant-form-item {
        margin: 20px 5px !important;

        &-control {
            font-family: "Segoe UI";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }
    }
}
html{
    scroll-behavior: smooth;
}
.ant-upload-list-picture-container{
    div{
        div{
            .ant-tooltip , .ant-tooltip-placement-top{
                display: none 
            }
        }
    }
}