.createTransfers{
    .transferBTN{
        margin-top: 22px;
        width: 120px;
        background-color: #6fb76c;
        border-color: #6fb76c;
        color: #fff;
        transition: 0.3s;
        margin-right: 80px;
        &:hover {
            background-color: #fff;
            color: #6fb76c;
        }
    }
}