.updateUserCompany{
    padding: 20px 0;
    .arrowCol{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 17px;
    }
    .inputsRow{
        margin-bottom: 15px;
    }
    .btnCol{
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
    }
    .tableLabel{
        font-weight: 700;
        font-size: 18px;
    }
}