.updateCompanyBalance, .driversList{
    direction: rtl;
    padding: 20px;
    .goBack{
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 50px;
        cursor: pointer;
    }
        .ant-row {
            margin-bottom: 50px;
            .ant-col {
                padding: 0px 10px;
                .ant-form-item-control{
                    padding: 0;
                    .ant-input-number{
                        width: 100%;
                    }
                }
                .infoLabel{
                    font-size: 16px;
                    font-weight: 500;
                }
                .ant-input-number{
                    .ant-input-number-handler-wrap{
                        left: 0;
                        right: unset;
                    }
                }
                input{
                    text-align: right;
                }
            }
            .btnCol{
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                button{
                    width: 120px;
                    background-color: #6fb76c;
                    border-color: #6fb76c;
                    color: #fff;
                    transition: 0.3s;
                    &:hover {
                        background-color: #6fb76c;
                        color: #fff;
                    }
                }
            }
        }
}

.driversList{
    .ant-row {
        margin-bottom: 20px;
    }
}