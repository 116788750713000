ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    height: 100vh;
    // width: 200px;
    // @media #{$lg-layout} {
    //     width: 180px;
    // }
    // @media #{$md-layout} {
    //     width: 200px;
    // }
    // padding: 0 0 0 200px;
}
ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.ant-menu-inline-collapsed {
    height: 100vh;
    // width: 250px;
    @media#{$extra-small-mobile} {
        width: 70px;
    }
    @media #{$small-mobile} {
        width: 56px;
    }
    @media #{$sm-layout} {
        width: 56px;
    }
    @media #{$md-layout} {
        width: 56px;
    }
    @media #{$lg-layout} {
        width: 56px;
    }
    @media #{$xl-layout} {
        width: 56px;
    }
}
.sideBar {
    .actionsNum {
        float: right;
        font-size: 12px;
    }
}
.navBtn {
    &:hover {
        color: black !important;
    }
}
//
.mainBodyGroups {
    padding: 0 15px;
    overflow-x: hidden;
    overflow-y: hidden;
    .card {
        width: 100%;
        margin-top: 10px;
        cursor: pointer;
        // transform-origin: 0 0;
        transition: transform 0.3s, visibility 0.3s ease-in-out;
        &:hover {
            transform: scale(1.007);
            background: #f5f5f5;
            overflow: hidden;
        }
    }
}
// .anticon svg {
//     color: $error !important;
// }
