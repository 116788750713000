.site-card-border-less-wrapper {
    padding: 20px;
    @media #{$small-mobile} {
        padding: 0;
        margin: 10px 0;
    }
    @media #{$extra-small-mobile} {
        padding: 0;
        margin: 10px 0;
    }
    background: #fbfbfbfa !important;
    .expensesHead {
        margin: 0 0 25px 0;
        .arrowBack {
            padding: 11px 14px;
            font-weight: 600;
            background-color: #fff;
            margin: 0 0 0 10px;
        }
        span {
            font-size: 18px;
            font-weight: bolder;
            font-family: fantasy;
        }
    }
    .userInfo {
        .cartNum {
            font-size: 14px;
            @media #{$small-mobile} {
                font-size: 15px;
            }
            @media #{$extra-small-mobile} {
                font-size: 15px;
            }
           
        }
    }
    .expenseForm {
        background: #fff;
        padding: 10px;
        border-radius: 5px;
        @media #{$small-mobile} {
            padding: 10px 0;
        }
        @media #{$extra-small-mobile} {
            padding: 10px 0;
        }
        .worthy {
            padding: 0 10px;
            font-size: 16px;
            @media #{$small-mobile} {
                font-size: 15px;
            }
            @media #{$extra-small-mobile} {
                font-size: 15px;
            }
        }
        .ant-row {
            padding: 0px 0px 5px 0 !important;
        }
        .ant-form-item {
            margin: 10px 10px;
        }
        .ant-row.ant-row-rtl.ant-form-item {
            margin: 10px 10px;
        }
        .label {
            margin: 8px 10px;
            font-weight: 600;
        }
        span.ant-select-selection-placeholder {
            font-weight: 400;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            height: 33px;
        }
        .ant-input-number.ant-input-number-rtl.ant-input-number-in-form-item {
            width: auto;
            @media #{$small-mobile} {
                width: 150px;
            }
            @media #{$extra-small-mobile} {
                width: 150px;
            }
        }
        .ant-radio-group {
            width: 100%;
            .ant-radio-wrapper {
                width: 48%;
                background: #fbfbfbfa;
                padding: 10px;
                margin-right: 0;
                margin-bottom: 10px;
                @media #{$small-mobile} {
                    font-size: 15px;
                    padding: 0;
                }
                @media #{$extra-small-mobile} {
                    font-size: 15px;
                    padding: 0;
                }
            }
            .ant-tag-green {
                margin: 0;
            }

            .cartType {
                font-size: 20px;
                color: #000;
                padding: 10px;
                font-family: auto;
                @media #{$small-mobile} {
                    font-size: 15px;
                }
                @media #{$extra-small-mobile} {
                    font-size: 15px;
                }
            }
            .cartNum {
                color: #000000b8;
                padding: 0 10px;
                padding: 0 10px;
                font-weight: 300;
                font-size: 16px;
                font-family: monospace;
            }
            .ant-radio-checked::after {
                color: #000;
                border: 1px solid #000;
            }
            .ant-radio-checked .ant-radio-inner {
                border-color: #000 !important;
            }
            .ant-radio-inner::after {
                background-color: #000;
            }
        }
        span.anticon.anticon-minus-circle {
            position: relative !important;
            top: 35px !important;
            color: red !important;
        }
        .ant-btn-dashed:hover,
        .ant-btn-dashed:focus {
            color: #000 !important;
            border-color: #000;
            background: #fff;
        }
        // .vod {
        //     border: 1px solid #000;
        //     padding: 10px;
        //     // width: 250px;
        // }
    }
}
.collectExpense {
    background: #fff;
    padding: 30px;
    
    border-top: 1px solid #ddd;
    .submitExpense {
        button {
            float: left;
            width: 200px;
            background-color: #6fb76c;
            color: #fff;
            border-color: #6fb76c;
            height: 50px;
            border-radius: 5px;
            transition: 0.3s;
            &:hover {
                background-color: #6fb76c;
                color: #fff;
            }
            @media #{$extra-small-mobile} {
                width: 160px;
                height: 35px;
                // text-align: center;
                margin: 5px auto;
                float: none;
            }
        }
        .confirm{
            margin: 0 15px;
            @media #{$small-mobile} {
               margin-bottom: 10px;
            }
            @media #{$extra-small-mobile} {
                margin-bottom: 10px;
            }
        }
        .back{
            background: #fff;
            color: #6fb76c;
            margin: 0 15px;
        }
    }
    .total {
        margin: -10px 0 0 0;
        span {
            font-size: 16px;
            @media #{$extra-small-mobile} {
                font-size: 13px;
            }
        }
        .amount {
            font-size: 21px;
            font-weight: 600;
            @media #{$extra-small-mobile} {
                font-size: 16px;
            }
        }
    }
}
[data-theme="dark"] .site-card-border-less-wrapper {
    background: #303030;
}
.reviewOrderBalance {
    width: 500px;
    padding: 5px 20px;
    border-radius: 5px;
    .driverInfo {
        margin: 0 0 20px 0;
        display: block;
        .info {
            padding: 0 0 30px 0;
        }
    }
    .summary {
        // width: 50%;
        .leftSide {
            float: left;
        }
        .rightSide {
            float: right;
        }
    }
}
.reviewDriverOrder {
    background-color: #fff;
    width: 800px;
    padding: 0 20px 20px;
    border-radius: 5px;
    .driverInfo {
        margin: 0 0 20px 0;
        display: block;
        border-bottom: 1px solid #ddd;
        .info {
            padding: 0 0 30px 0;
        }
    }
}
.btns {
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
        background-color: #6fb76c;
        color: #fff;
        border-color: #6fb76c;
    }

    .decline,
    .approve {
        width: 200px;
        background-color: #6fb76c;
        color: #fff;
        border-color: #6fb76c;
        height: 50px;
        border-radius: 5px;
        transition: 0.3s;
        margin: 20px 20px 0 0;
    }
    .approve {
        background-color: #6fb76c;
        color: #fff;
        &:hover {
            background-color: #6fb76c;
            color: #fff;
            border-color: #6fb76c;
        }
        &:active {
            background-color: #6fb76c;
            color: #fff;
            border-color: #6fb76c;
        }
    }
    .decline {
        background-color: #fff;
        color: #6fb76c;
        border-color: #6fb76c;
        &:hover,
        &:active {
            background-color: #fff;
            color: #6fb76c;
            border-color: #6fb76c;
        }
    }
}

.ant-click-animating-node {
    display: none;
}
