.CategoriesAndBrands{
    .images{
        margin: 15px 0;
    }
    .ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture {
        width: 90%;
        border: 1px solid #ddd;
        /* color: #000; */
    }
    span.ant-upload-list-item-name{
        color: #000;
    }
}