.bulkCreateTruckingUsers{
    direction: ltr !important;
    .head{
        max-width: 70%;
    }
    .anticon-exclamation-circle{
        margin-right: 5px;
    }
    .usersDataTable{
        white-space: nowrap;
    }
}
.validation-en{
    padding: 25px 0;
    width: 600px;
    li{
        font-size: 18px;
    }
    span{
        display: inline-block;
        margin-bottom: 10px;
    }
    ::marker{
        font-size: unset !important;
    }
    .cancelInstructionContainer{
        display: flex;
        justify-content: flex-end;
    }
}
.submitUsers{
    .reUpload{
        float: right !important;
    }
}
.truckUsersSuccess{
    display: flex;
    flex-direction: column;
    align-items: center;
    .reUpload{
        float: unset !important;
    }
}