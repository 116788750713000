.main {
    height: 100vh;
    overflow: hidden;
    background-image: linear-gradient(to right, rgba(255, 0, 0, 0), #bcffb961);

    .container {
        text-align: center;
        position: relative;
        top: 120px;
        @media #{$small-mobile} {
            position: relative;
            top: 75px;
        }
        .logo {
            width: 200px;
            @media #{$extra-small-mobile} {
                width: 150px;
            }
        }
        .ant-row {
            padding: 0 0 0 0;
            .ant-form-login {
                display: flex !important;
                justify-content: center;
                position: relative;
                top: 30px;
            }
            .passForgot {
                float: left;
                font-size: 13px;
                color: #004e64;
                text-decoration: underline;
                cursor: pointer;
                margin: -7px 85px;
            }
            .ant-input-affix-wrapper {
                width: 301px;
                float: left;
                border-radius: 5px;
                margin-top: 10px;
            }
            .ant-form-item {
                margin-bottom: 0;
            }
            .ant-form-item-explain-error {
                div {
                    font-size: 13px;
                    width: 300px;
                    text-align: left;
                    @media #{$extra-small-mobile} {
                        font-size: 12px;
                    }
                }
            }
            .ant-btn {
                width: 300px;
                float: left;
                margin: 10px 0px;
                border-radius: 5px;
                background-color: #59a948c2;
                border-color: #59a948c2;
            }
            .disabled-ant-btn {
                width: 300px;
                float: left;
                margin: 10px 0px;
                border-radius: 5px;
                color: #fff;
                background-color: $disabled;
                border-color: $disabled;
            }
        }
        input.pincode-input-text {
            border-radius: 5px;
            // border: 1px solid rgb(53 152 32);;
        }
        .timeOut{
            float: left;
            padding: 0 0 0 17px;
            color: $error;
        }
    }
    .bin {
        width: 268px !important;
        margin: 0 11px !important;
        
    }
    .bin-disabled {
        color: #fff;
        background-color: $disabled !important;
        border-color: $disabled !important;
    }
    .note {
        text-align: left;
        margin: 0 0 0 -57px;
        font-size: 12px;
        color: #00000094;
    }
}
