.userStatus{
    .ant-row{
        .ant-col{
            padding: 5px 5px 5px 0 !important; 
        }
    }
    label {
        span {
            display: inline-block;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun;
            line-height: 1;
            content: "*";
        }
    }
    .ant-table-body{
        max-height: 400px !important;
    }
}