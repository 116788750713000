.revokeRequest{
    margin-top: 30px;
    .ant-form{
        min-width: 500px;
        .ant-col{
            .ant-picker{
                width: 100%;
            }
        }
        .revokeBtn{
            padding-left: 20px;
        }
    }
}