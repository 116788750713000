.groupFees {
    .groupName {
        .ant-row {
            justify-content: center;
        }
        .createGroupBtn {
            background-color: $deepTeal;
            border-color: $deepTeal;
            margin: 15px auto;
            width: 50%;
            justify-content: center;
            display: flex;
            &:hover {
                background-color: $deepTeal;
                border-color: $deepTeal;
            }
            &:focus {
                background-color: $deepTeal;
                border-color: $deepTeal;
            }
        }
    }
    .assignService {
        border: 1px solid #ddd;
        padding: 25px 0;
        // margin: 50px 0;
        .ant-row {
            .colOfAssign {
                margin: 0 15px;
                .createGroupBtn {
                    background-color: $deepTeal;
                    border-color: $deepTeal;
                    margin-top: 22px;
                    &:hover {
                        background-color: $deepTeal;
                        border-color: $deepTeal;
                    }
                    &:focus {
                        background-color: $deepTeal;
                        border-color: $deepTeal;
                    }
                }
            }
        }
    }
}
