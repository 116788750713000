.hrEditUser {
    .ant-col.ant-col-24.ant-col-rtl {
        padding: 10px 2px;
    }
    .ant-row.ant-form-item {
        margin: 10px 0;
    }
    span.optional {
        color: #787777d9;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        padding-right: 5px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
            "Open Sans", "Helvetica Neue", sans-serif;
    }
    .editFormBtns {
        float: left;
        .confirmEdit,
        .cancelEdit {
            width: 104px;
            border-radius: 5px;
            height: 32px;
            padding: 4px 15px;
            gap: 8px;
        }
        .confirmEdit {
            margin: 0 10px;
            background: $lightGreen;
            color: #fff;
            border: none;
            &:hover {
                background: $lightGreen;
                border: none;
            }
            &:focus {
                background: $lightGreen;
                border: none;
            }
        }
        .cancelEdit{
            color: rgba(0, 0, 0, 0.85);
            border-color: #D9D9D9;
            &:hover {
                color: rgba(0, 0, 0, 0.85);
                border-color: #D9D9D9;
            }
            &:focus {
                color: rgba(0, 0, 0, 0.85);
                border-color: #D9D9D9;
            }
        }
    }
}
