.uploadUserDocs {
    .uploaderCol{
        margin: 0 0 15px 0;
    .oldPhoto {
        border: 1px solid #ddd;
        padding: 7px 15px;
        width: 327px;
        height: 65px;
        margin: 10px 0;

        .placeholder {
            text-align: center;
            margin: 0 23px;
            justify-content: center;
        }

        .deleteIcon {
            float: right;
            margin: 10px -3px;
            color: red;
        }
    }
    .ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture{
        width: 70%;
    }
    margin: 20px 0;
    input{
        width: 90%;
        margin: 5px 0;
    }
    .ant-upload-list-picture .ant-upload-list-item-error, .ant-upload-list-picture-card .ant-upload-list-item-error{
        border-color: #ddd;
    }
    .ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-text-icon > .anticon, .ant-upload-list-item-error .ant-upload-list-item-name{
        color: #000;
    }
    .user .ant-upload-list-item-error, .user .ant-upload-list-item-error .ant-upload-text-icon > .anticon, .user .ant-upload-list-item-error .ant-upload-list-item-name{
        color: #000;
    }
}
}