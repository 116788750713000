.bulkUpdateUsersHrInfo,.bulkCreateProducts, .bulkCreateTruckingUsers {
    padding: 25px;
    .userStatus {
        margin: 10px 0;
    }
    .ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
        display: none;
    }

    .uploadBtn {
        text-align: center;
        margin: auto;
        display: flex;
    }
    /*Stepper*/
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
        background-color: $lightGreen !important;
        border: 1px solid $lightGreen !important;
    }
    .ant-steps-item-wait .ant-steps-item-icon {
        border: 1px solid $lightGreen !important;
    }
    .ant-steps-item-finish .ant-steps-item-icon {
        background-color: $lightGreen !important;
        border: 1px solid $lightGreen !important;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        color: #fff !important;
    }
    /**Step 1**/
    .instructions {
        .head {
            padding: 20px 0 0;
            line-height: 18px;
            font-weight: 700;
            font-size: 13px;
        }
        .fillData {
            color: $lightGreen;
            cursor: pointer;

            .fillHint {
                margin-right: 5px;
            }
        }
        .sample {
            font-weight: 500;
            width: 200px;
            height: 40px;
            border-radius: 4px;
            font-size: 12px;
            float: left;
            color: $lightGreen;
            border: 1px solid $lightGreen;
            margin-top: 25px;
            &:hover {
                border: 1px solid $lightGreen;
            }
        }
    }
    .validation {
        .list {
            li {
                font-weight: 700 !important;
                font-size: 13px !important;
            }
        }
    }
    .uploaded {
        padding: 20px auto;
        .all {
            background: rgb(247, 247, 247);
            color: rgb(173, 174, 175) !important;
            padding: 10px 14px;
            width: 250px;
            font-size: 13px !important;
            font-weight: 500;
        }
        .failed {
            background: rgb(251, 241, 241);
            color: rgb(215, 82, 72) !important;
            padding: 10px 0;
            width: 250px;
            font-size: 13px !important;
            font-weight: 500;
        }
        
        .successes {
            background: rgb(244, 248, 242);
            color: rgb(100, 167, 67) !important;
            padding: 10px 0;
            width: 250px;
            font-size: 13px !important;
            font-weight: 500;
            float: left;
        }
        margin: 20px auto;
    }
    li {
        font-weight: 700 !important;
        font-size: 13px !important;
    }
    .uploadBtn {
        text-align: center;
        padding: 15px 30px;
        margin: auto;
        color: #fff;
        background: $lightGreen;
        height: 60px;
        border: 1px solid $lightGreen;
    }
    /*Step2*/
    .approveUsers, .submitUsers, .truckUsersSuccess {
        margin: 0 0 50px 0;
        .waiting {
            margin: 50px 0;
            .check {
                font-weight: 700;
                font-size: 14px;
                color: #424b5a;
                line-height: 18px;
            }
            .checkHint {
                font-family: "IBM Plex Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #424b5a;
            }
        }
        .downloaded {
            padding: 10px 0;
        }
        .question,
        .info1,
        .info2 {
            font-size: 13px;
            font-weight: 500;
        }
        .approve,
        .notApprove {
            width: fit-content;
            border-radius: 0;
            height: 40px;
        }
        .approve {
            color: #fff;
        }
        .notApprove {
            color: $lightGreen;
            border: 1px solid $lightGreen;
            &:hover {
                color: $lightGreen;
                border: 1px solid $lightGreen;
            }
        }
        .reUpload {
            float: left;
            margin: 20px 0;
            background: #7cc58f;
            color: #fff;
            width: 200px;
            height: 40px;
            border-radius: 4px;
            &:hover {
                border: 1px solid #7cc58f;
            }
        }
        .down {
            // float: left;
            // margin: 20px 0;
            background: #fff;
            color: #7cc58f;
            width: fit-content;
            height: 40px;
            border-radius: 4px;
            &:hover {
                border: 1px solid #7cc58f;
            }
        }
    }
    .ant-result-success .ant-result-icon > .anticon {
        color: $lightGreen;
    }
    .ant-result-rtl .ant-result-extra > *:last-child {
        width: fit-content;
        border-radius: 0;
        height: 40px;
        background: #fff;
        color: $lightGreen;
        border: 1px solid $lightGreen;
    }
    .upload {
        background: #fafafa;
        border: 1px solid #d9d9d9;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px 0px;
        height: 219px;
        cursor: pointer;
        .ant-upload-drag-icon {
            color: #7cc58f;
            font-size: 45px;
            // width: 35px;
            // height: 35px;
            margin: 0 0;
        }
        .ant-upload-text {
            font-weight: 700;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 0;
        }
        .ant-upload-hint {
            font-weight: 400;
            font-size: 13px;
            text-align: center;
            color: rgba(0, 0, 0, 0.45);
        }
        .error-hint {
            font-size: 13px;
            color: #cc4949;
            margin-top: 15px;
        }
    }
    .maxSize {
        font-size: 13px;
        text-align: right;
        color: rgba(0, 0, 0, 0.45);
    }
}

span.simpleDesc {
    color: rgba(0, 0, 0, 0.6);
}
.validationProds{
    ::marker {
        color: $lightGreen;
        font-size: 15px;
    }
    width: 500px;
    margin: -30px 0;
    .cancelInstruction {
        font-weight: 500;
        width: 200px;
        height: 40px;
        border-radius: 4px;
        font-size: 12px;
        float: left;
        color: #000;
        border: 1px solid #d9d9d9;
        margin: 15px 0;
        &:hover {
            border: 1px solid #d9d9d9;
        }
    }
}
.validation {
    ::marker {
        color: $lightGreen;
        font-size: 30px;
    }
    width: 500px;
    margin: -30px 0;
    .cancelInstruction {
        font-weight: 500;
        width: 200px;
        height: 40px;
        border-radius: 4px;
        font-size: 12px;
        float: left;
        color: #000;
        border: 1px solid #d9d9d9;
        margin: 15px 0;
        &:hover {
            border: 1px solid #d9d9d9;
        }
    }
}
.ant-modal .ant-modal-body {
    max-height: 640px;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7cc58f;
    border-color: #7cc58f;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #7cc58f;
}

.ant-checkbox-inner:hover {
    border: 1px solid #7cc58f;
}

.ant-checkbox-checked::after {
    border: 1px solid #7cc58f !important;
}
.ant-table-selection {
    padding: 0 10px 0 0;
}
